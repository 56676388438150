import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyAizXhlH9yfJjNFdk_tqgmb3cY9UiMpoYM",
  authDomain: "supree-portal.firebaseapp.com",
  projectId: "supree-portal",
  storageBucket: "supree-portal.appspot.com",
  messagingSenderId: "1073948438577",
  appId: "1:1073948438577:web:ccc018e1038dd816b1e510",
  measurementId: "G-3Q81J1TWVN"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()
const db = firebase.firestore()

export { db }