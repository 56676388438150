<template>
  <div id="app" style="background-color: #222222">
    <div class="relative overflow-hidden">
      <!-- SVG Quadradinhos -->
      <div class="hidden sm:block sm:absolute sm:inset-0" aria-hidden="true"><svg class="absolute bottom-0 right-16 transform translate-x-1/2 mb-48 text-teal-700 lg:top-0 lg:mt-16 lg:mb-0 xl:transform-none xl:translate-x-0" width="364" height="384" viewBox="0 0 364 384" fill="none"><defs><pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" fill="currentColor" /></pattern></defs><rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" /></svg></div>
      <!-- Header -->
      <div class="relative pt-6 pb-16 sm:pb-24">
        <main class="mt-16 sm:mt-24">
          <div class="mx-auto max-w-7xl">
            <div class="lg:grid lg:grid-cols-12 lg:gap-8">
              <div class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  <!-- <img class="mb-10 h-11 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=teal&shade=500" alt="Workflow"> -->
                  <div class="w-full flex justify-start sm:justify-center lg:justify-start"><p class="mb-5 w-min rounded-md px-4 py-2 cursor-default text-5xl font-bold font-mono text-teal-400" style="background-color: #363636">Supree</p></div>
                  <div class="inline-flex space-x-4 cursor-default">
                    <span class="relative rounded px-2.5 py-1 text-xs font-semibold text-teal-500 tracking-wide" style="background-color: #363636">EM BREVE</span>
                    <span @click="$refs.inptName.focus()" class="inline-flex cursor-pointer items-center text-sm font-medium text-teal-400 space-x-1">
                      <span>Receba alertas de novidades</span>
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" /></svg>
                    </span>
                  </div>
                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
                    <span class="md:block">Diferente de todos,</span>
                    <span class="md:block"> mas <span class="text-teal-400">igual a você</span>.</span>
                  </h1>
                  <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Um novo marketplace pronto para facilitar o seu dia, com diversos setores de comércio no mesmo lugar!
                  </p>
                </div>
              </div>
              <div class="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div class="bg-white mx-4 sm:max-w-md sm:w-full sm:mx-auto rounded-lg sm:overflow-hidden">
                  <div class="px-4 py-8 sm:px-10">
                    <div class="mt-2">
                      <form class="space-y-6">
                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                          <div v-if="message" class="p-2 rounded-lg bg-teal-400 shadow-lg sm:p-3">
                            <div class="flex items-center justify-between flex-wrap">
                              <div class="w-0 flex-1 flex items-center">
                                <span class="flex p-2 rounded-lg bg-teal-700">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>
                                </span>
                                <p class="ml-3 font-medium text-white truncate">Obrigado, te manteremos atualizado!</p>
                              </div>
                            </div>
                          </div>
                        </transition>
                        <div>
                          <label for="name" class="sr-only">Nome completo</label>
                          <input v-model="name" :disabled="loading" :class="loading ? 'bg-gray-100' : ''" ref="inptName" type="text" name="name" id="name" autocomplete="off" placeholder="Nome completo" required class="block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 rounded-md">
                          <label v-if="errorName && !name" class="text-red-500 text-xs">Campo requerido</label>
                        </div>
                        <div>
                          <label for="email" class="sr-only">Email</label>
                          <input v-model="email" :disabled="loading" :class="loading ? 'bg-gray-100' : ''" type="email" name="email" id="email" autocomplete="off" placeholder="Email" required class="block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 rounded-md">
                          <label v-if="errorEmail && !email" class="text-red-500 text-xs">Campo requerido</label>
                        </div>
                        <div>
                          <label for="phone" class="sr-only">Celular</label>
                          <input v-model="phone" :disabled="loading" :class="loading ? 'bg-gray-100' : ''" v-mask="['(##) ####-####', '(##) #####-####']" id="phone" name="phone" type="text" inputmode="numeric" placeholder="Celular" autocomplete="off" required class="block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300 rounded-md">
                          <label v-if="errorEmail && !phone" class="text-red-500 text-xs">Campo requerido</label>
                        </div>
                        <button @click="save()" type="button" class="w-full flex justify-center relative py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-400 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <span>Inscrever-se</span>
                          <svg v-if="loading" class="absolute text-white right-2 animate-spin h-5 w-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" /> <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" /></svg>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div class="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10 rounded-b-lg">
                    <p class="text-xs leading-5 text-gray-500">Ao se inscrever, você aceita nossa <a href="#" class="font-medium text-gray-900 hover:underline">Política de Privacidade</a>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- Marketplace -->
    <div class="relative py-16">
      <div class="hidden absolute top-0 inset-x-0 h-1/2 lg:block" aria-hidden="true" style="background-color: #222222"></div>
      <div class="max-w-7xl mx-auto bg-teal-600 lg:bg-transparent lg:px-8">
        <div class="lg:grid lg:grid-cols-12">
          <div class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div class="absolute inset-x-0 h-1/2 lg:hidden" aria-hidden="true" style="background-color: #222222"></div>
            <div class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <img class="object-cover object-center rounded-3xl shadow-2xl" :src="require('@/assets/user.jpg')" alt="Usuário">
              </div>
            </div>
          </div>
          <div class="relative bg-teal-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div class="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
              <svg class="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true"><defs><pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" class="text-teal-500" fill="currentColor" /></pattern></defs><rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" /></svg>
              <svg class="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true"><defs><pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse"><rect x="0" y="0" width="4" height="4" class="text-teal-500" fill="currentColor" /></pattern></defs><rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" /></svg>
            </div>
            <div class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2 class="text-3xl font-extrabold text-white" id="join-heading">Fácil como a vida tem que ser...</h2>
              <p class="text-lg text-white text-justify">Se prepare para um app com uma ampla variedade de setores dos comércios da sua região. Todos a um click do seu próximo pedido.</p>
              <div class="relative pt-6 sm:pt-4">
                <dt>
                  <svg class="absolute h-6 w-6 text-teal-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-white">Diversos setores</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-300">
                  Esqueça as limitações de categorias de produtos dos apps atuais.
                </dd>
              </div>
              <div class="relative">
                <dt>
                  <svg class="absolute h-6 w-6 text-teal-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" /></svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-white">Receba em casa</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-300">
                  Seus pedidos em sua porta, como você já está acostumado.
                </dd>
              </div>
              <div class="relative">
                <dt>
                  <svg class="absolute h-6 w-6 text-teal-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-white">Sem filas</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-300">
                  Chega de filas nos caixas de supermercados e lojas.
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <footer>
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div class="flex justify-center space-x-6 md:order-2">
          <a href="#" class="text-gray-400 hover:text-teal-500">
            <span class="sr-only">Instagram</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
          </a>
          <a href="https://github.com/supree-dev" target="_blank" class="text-gray-400 hover:text-teal-500">
            <span class="sr-only">GitHub</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
          </a>
        </div>
        <p class="mt-8 md:mt-0 md:order-1 text-center cursor-default text-base text-gray-400">&copy; 2021 Supree. Todos os direitos reservados.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { db } from '@/firebase'
import { mask } from 'vue-the-mask'

export default {
  data() { return { message: false, loading: false, name: '', email: '', phone: '', errorName: false, errorEmail: false, errorPhone: false } },

  methods: {
    async save() {
      if (!this.name) { this.errorName = true; return }
      if (!this.email) { this.errorEmail = true; return }
      if (!this.phone) { this.errorPhone = true; return }

      this.loading = true
      await db.collection('Subscribers').add({ Name: this.name, Email: this.email, Phone: this.phone })
      
      this.message = true
      this.name = '', this.email = '', this.phone = '', this.errorName = false, this.errorEmail = false, this.errorPhone = false
      
      this.loading = false
    }
  },

  directives: { mask }
}
</script>